import React from 'react';
import { ReactComponent as IconGithub } from './assets/icons/github.svg';
import Logo from './assets/icons/logo_white.svg';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <img alt="logo" src={Logo}></img>
          </div>
          <div className="social">
            <a href="https://github.com/oxydev" title="GitHub" target="_blank" rel="noopener noreferrer">
              <IconGithub className="icon" />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Crypto is not Cryptical with Crypt<span className="y_in_name">Y</span>cal!</h1>
            <p>Website & App are coming soon. Please check back to know more. Shoot us an email if you're curious.</p>
          </div>
          <a href="mailto:info@cryptycal.com">
            <div className="cta">Send us an email</div>
          </a>
          <a href="https://www.canva.com/design/DAFf5DJ3O10/H4MrbKHE146tVQOejEwMeA/view">
            <div className="cta secondButton">Pitch Deck</div>
          </a>
        </div>
        <div className="footer">
          <span>Made by <a className="underlined" href="https://github.com/oxydev" target="_blank" rel="noopener noreferrer">OxyDev</a> </span>
        </div>
      </div>
    );
  }
}

export default App;